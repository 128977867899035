<template>
    <div v-for="(video, index) in bloc.videos" :key="index" class="item_videos">
      <a :href="video.link" target="_blank" class="item_videos--link text-decoration-none">
        <img :src="getImgUrl(video.image)" :alt="video.image" style="max-width: 100%"/>
        <div class="my-4 text-start c-blue-dark f-middle fw-bold text-decoration-underline">{{video.title}}</div>
        <div class="text-start f-little text-black">{{video.text}}</div>
      </a>

      <div class="d-flex spacer horizontal-full" v-if="index != (bloc.videos.length-1)">
        <div class="spacer--separator"></div>
      </div>
    </div>
</template>

<script>
export default {
  props: ['bloc'],
    methods: {
        checkType(type, check) {
            if(check === type) {
                return true;
            }
            return false;
        },
        getImgUrl(img) {
            return require('../assets/img/'+img)
        },
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">


</style>
